const articles = [
  {
    id: 1,
    sid: "tax_immo_general_1",
    url: 'steuern-immobilienkauf',
    date: '2024-11-26',
    header: "Steuern beim Immobilienkauf",
    shortText: "Steuerliche Aspekte beim Immobilienkauf: Alles Wichtige zur Grunderwerbsteuer, Optimierungen und Vorteilen.",
    imageLink: '/media/picture_immo_tax_scale.png',
    content: [
      { 
        type: 'paragraph', 
        text: "Der Kauf einer Immobilie ist nicht nur eine bedeutende finanzielle Entscheidung, sondern bringt auch steuerliche Verpflichtungen und Vorteile mit sich. Hier ein Überblick über die wichtigsten Steuern und Optimierungsmöglichkeiten."
      },
      { type: 'heading', level: 2, text: "1. Grunderwerbsteuer (GrESt)" },
      { 
        type: 'paragraph', 
        text: "Die Grunderwerbsteuer ist die bekannteste Steuer beim Immobilienkauf. Sie wird einmalig beim Erwerb einer Immobilie oder eines Grundstücks fällig. Die Höhe der Steuer variiert je nach Bundesland zwischen 3,5 % und 6,5 % des Kaufpreises."
      },
      { type: 'heading', level: 3, text: "Beispielrechnung" },
      { 
        type: 'list', 
        items: [
          "Kaufpreis der Immobilie: 400.000 €",
          "Grunderwerbsteuer (z. B. in Bayern, 6,5 %): 400.000 × 0,065 = 26.000 €"
        ]
      },
      { type: 'heading', level: 3, text: "Ausnahmen" },
      {
        type: 'list',
        items: [
          "Immobilien, die innerhalb der Familie übertragen werden (z. B. Erbschaften oder Schenkungen).",
          "Der Kaufpreis liegt unter der Bagatellgrenze (häufig 2.500 €)."
        ]
      },
      {
        type: 'table',
        headers: ["Bundesland", "Grunderwerbsteuer"],
        rows: [
          ["Bayern", "3,5 %"],
          ["Sachsen", "3,5 %"],
          ["Hamburg", "4,5 %"],
          ["Baden-Württemberg", "5,0 %"],
          ["Bremen", "5,0 %"],
          ["Niedersachsen", "5,0 %"],
          ["Rheinland-Pfalz", "5,0 %"],
          ["Sachsen-Anhalt", "5,0 %"],
          ["Berlin", "6,0 %"],
          ["Mecklenburg-Vorpommern", "6,0 %"],
          ["Hessen", "6,0 %"],
          ["Thüringen", "6,5 %"],
          ["Brandenburg", "6,5 %"],
          ["Nordrhein-Westfalen", "6,5 %"],
          ["Saarland", "6,5 %"],
          ["Schleswig-Holstein", "6,5 %"]
        ]
      },
      { type: 'heading', level: 2, text: "2. Grundsteuer" },
      { 
        type: 'paragraph', 
        text: "Die Grundsteuer wird jährlich fällig und betrifft alle Immobilieneigentümer. Sie dient der Finanzierung von Kommunen und basiert auf dem Wert des Grundstücks und der Immobilie. Ab 2025 gilt die neue Grundsteuerreform, die eine Neubewertung aller Immobilien mit sich bringt."
      },
      { type: 'heading', level: 3, text: "Berechnung" },
      {
        type: 'list',
        items: [
          "Einheitswert: Vom Finanzamt festgelegter Immobilienwert.",
          "Steuermesszahl: Vom Gesetzgeber vorgegeben.",
          "Hebesatz: Wird von der Kommune festgelegt."
        ]
      },
      { type: 'paragraph', text: "Formel: Grundsteuer = Einheitswert × Steuermesszahl × Hebesatz" },
      { type: 'heading', level: 2, text: "3. Umsatzsteuer bei gewerblichen Immobilienkäufen" },
      { 
        type: 'paragraph', 
        text: "Beim Kauf einer privat genutzten Immobilie fällt keine Umsatzsteuer an. Bei gewerblichen Immobilien kann der Verkäufer auf Wunsch Umsatzsteuer (19 %) ausweisen."
      },
      { 
        type: 'paragraph', 
        text: "Vorteil für Unternehmer: Wenn die Immobilie für unternehmerische Zwecke genutzt wird, kann die Vorsteuer geltend gemacht werden."
      },
      { type: 'heading', level: 2, text: "4. Spekulationssteuer beim Wiederverkauf" },
      { 
        type: 'paragraph', 
        text: "Wer eine Immobilie innerhalb von 10 Jahren nach dem Kauf wieder verkauft und dabei einen Gewinn erzielt, muss Spekulationssteuer zahlen. Die Höhe richtet sich nach dem persönlichen Einkommensteuersatz."
      },
      { type: 'heading', level: 3, text: "Ausnahme" },
      { 
        type: 'paragraph', 
        text: "Selbstgenutzte Immobilien sind steuerfrei, wenn sie im Verkaufsjahr und den beiden Vorjahren ausschließlich privat genutzt wurden."
      },
      { type: 'heading', level: 2, text: "5. Steuerliche Vorteile beim Immobilienkauf" },
      { 
        type: 'paragraph', 
        text: "Freibeträge bei Schenkung und Erbschaft:"
      },
      {
        type: 'table',
        headers: ["Verwandtschaftsgrad", "Freibetrag"],
        rows: [
          ["Ehepartner", "500.000 €"],
          ["Kinder", "400.000 €"],
          ["Enkel", "200.000 €"],
          ["Eltern und Großeltern", "100.000 €"],
          ["Geschwister, Nichten, Neffen", "20.000 €"],
          ["Nicht verwandte Personen", "20.000 €"]
        ]
      },
      { type: 'paragraph', text: "Steuerfreies Familienheim: Erblasser und Erbe müssen das Haus selbst als Hauptwohnsitz genutzt haben. Die Wohnfläche darf bestimmte Grenzen nicht überschreiten (z. B. 200 m² für Ehepartner)." },
      { type: 'heading', level: 2, text: "6. Steueroptimierung" },
      { 
        type: 'list',
        items: [
          "Abschreibung (AfA): Abschreibung von Gebäudewerten zur Reduzierung des zu versteuernden Einkommens. [Mehr dazu hier →](#)",
          "Ausweisung von beweglichem Inventar: Kosten für Kamine, Einbauküchen oder Mobiliar können separat ausgewiesen werden. Diese lassen sich häufig schneller abschreiben als das Gebäude.",
          "Ausweis von Grund- und Immobilienwerten: Der Bodenwert und Gebäudewert können separat angegeben werden. Abweichungen vom Bodenrichtwert werden jedoch selten akzeptiert."
        ]
      },
      {
        type: 'relatedarticles',
        items: [
          "ID123",
          "ID124"
        ]
      },
    ],
    categories: ["Steuerliche Aspekte"]
  },    
  
  {
      id: 3,
      sid: "ID123",
      url: 'build_home',
      date: '2024-11-15',
      header: "How to Buy Your First Home",
      shortText: "Learn the essential steps for purchasing your first property, from budgeting to signing the contract.",
      imageLink: "https://cdn.pixabay.com/photo/2013/07/12/17/47/test-pattern-152459_1280.png",
      content: [
        { type: 'heading', level: 2, text: "Lalala Impressum" },
        { type: 'paragraph', text: "Buying your first home is a big step! Here, we'll walk you through the essential stages to make the process as smooth as possible." },
        { type: 'heading', level: 3, text: "Step 1: Budgeting" },
        { type: 'paragraph', text: "Understanding your budget is crucial before making any offers..." },
        { type: 'image', src: "https://cdn.pixabay.com/photo/2013/07/12/17/47/test-pattern-152459_1280.png", alt: "Home buying budget" },
        {
          type: 'list',
          items: [
            "Check the neighborhood for schools, parks, and public transport options.",
            "Consider the potential for property value growth in the area.",
            "Think about your long-term plans and how the home will meet your future needs."
          ]
        },
        { type: 'paragraph', text: "Good luck with your first home purchase!" }
      ],
      categories: ["Immobilieninvestment"]
    },    
    {
      id: 2,
      sid: "ID124",
      url: 'lalaTest',
      date: '2024-11-15',
      header: "Understanding Real Estate Investment",
      shortText: "An introduction to the fundamentals of investing in real estate, including property types, risks, and returns.",
      imageLink: "https://cdn.pixabay.com/photo/2013/07/12/17/47/test-pattern-152459_1280.png",
      content: [
        { type: 'heading', level: 2, text: "Lalala Impressum" },
        { type: 'paragraph', text: "Buying your first home is a big step! Here, we'll walk you through the essential stages to make the process as smooth as possible." },
        {
          type: 'list',
          items: [
            "Check the neighborhood for schools, parks, and public transport options.",
            "Consider the potential for property value growth in the area.",
            "Think about your long-term plans and how the home will meet your future needs."
          ]
        },
        { type: 'paragraph', text: "Good luck with your first home purchase!" }
      ],
      categories: ["Immobilieninvestment", "Steuerliche Aspekte"]
    },
    // Add more articles as needed
  ];
  
  export default articles;
  