// src/Map.js
import React, { useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom' // Import useLocation
import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css' // Import MapLibre CSS

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faLayerGroup } from '@fortawesome/free-solid-svg-icons'

// own utils
import colors from './utils/colors'
import config from './utils/config'
import { createFilteredLayer } from './utils/createFilteredLayer'
import { baseMapConfig } from './utils/basemap' // Import the base map configuration
import { getUserLocation } from './utils/getUserLocation'
import { addTileLayer } from './utils/addTileLayer'

// own Components:
import Sidebar from './components/sidebar/Sidebar'
import Navbar from './components/navbar/Navbar'
import Overlay from './components/overlay/Overlay' // Import the Overlay component
import SearchBar from './components/searchbar'
import Legend from './components/legend/Legend' // Import the Legend component
import VersionSticker from './components/VersionSticker'

//own hooks
import useDeviceType from './hooks/useDeviceType'

// own functions:
import toggleLayer from './functions/toggleLayer'
import mapClickHandler from './functions/mapClickHandler'
import { toggleView } from './functions/toggleView'
import generateFilterOptions from './utils/filters/generateFilterOptions'

//css
import './css/Map.css'

//Styles
import useStandardButtonStyle from './styles/standardButtonStyle'

const Map = () => {
  const mapContainerRef = useRef(null)
  const [map, setMap] = useState(null)
  const [layersVisibility, setLayersVisibility] = useState({
    'immo_layer-fill-extrusion': true,
    'flurstuecke-fill': true
    // Default visibility fopr Buttons etc. for immo_layer and flurstuecke
  }) // maybe needs to be initialised with all the layers available (sourceIDs)
  const [overlayData, setOverlayData] = useState({})
  const [overlayParcelsData, setOverlayParcelsData] = useState({})
  const [showOverlay, setShowOverlay] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isNavbarOpen, setIsNavbarOpen] = useState(false)
  const [filters, setFilters] = useState({ filterRange: [] })

  //3D features
  const [is2DView, setIs2DView] = useState(false)

  const markerRef = useRef(null)
  const searchMarker = useRef(null)

  const { isMobile } = useDeviceType()

  const standardButtonStyle = useStandardButtonStyle()

  // Get location object
  const location = useLocation()

  // Extract query parameter 'q'
  const queryParams = new URLSearchParams(location.search)
  const qSearchQuery = queryParams.get('q') || '' // Default to empty string if not present
  console.log('qSearchQuery in Map: ', qSearchQuery)

  //TODO fix how colors are transferred -> central definition of Layers
  //this is only input to legend
  const layerLegend = Object.keys(config.filters.buildingTypesCategory).map(
    key => ({
      key: key, // Capitalize the first letter
      color: colors.colorMappingBuildingType[key]
    })
  )

  useEffect(() => {
    const initializeMap = async () => {
      const mapInstance = new maplibregl.Map({
        container: mapContainerRef.current,
        style: baseMapConfig,
        center: [6.72, 51.14],
        zoom: 14,
        minZoom: 14,
        maxZoom: 18,
        pitch: 60
      })

      mapInstance.fitBounds(config.map.startBounds, {
        padding: 20,
        maxZoom: 14,
        duration: 0
      })

      // Add event listener for mousemove
      mapInstance.on('mousemove', 'immo_layer-fill-extrusion', e => {
        // Change cursor style when hovering over the building layer
        mapInstance.getCanvas().style.cursor = 'pointer' // Change to your desired cursor style
      })

      // Reset cursor when not hovering over buildings
      mapInstance.on('mouseleave', 'immo_layer-fill-extrusion', () => {
        mapInstance.getCanvas().style.cursor = '' // Reset to default cursor
      })

      //slow down scrolling
      mapInstance.scrollZoom.setWheelZoomRate(1 / 4000)

      // Add the Layer
      mapInstance.on('load', async () => {
        // Add multiple layers by calling the addTileLayer function
        await addTileLayer(
          mapInstance,
          config.tileserver.layers,
          [0, 1],
          is2DView,
          config.filters.global.buildingTypes
        )
        await addTileLayer(
          mapInstance,
          config.tileserver.layers,
          [2],
          is2DView,
          config.filters.global.buildingTypes
        )
        // Ensure the immo_layer is visible on load
        if (mapInstance.getLayer('immo_layer-fill-extrusion')) {
          mapInstance.setLayoutProperty(
            'immo_layer-fill-extrusion',
            'visibility',
            'visible'
          )
        }
        // Ensure the flurstuecke-fill is visible on load
        if (mapInstance.getLayer('flurstuecke-fill')) {
          mapInstance.setLayoutProperty(
            'flurstuecke-fill',
            'visibility',
            'visible'
          )
        }
        // Ensure the flurstuecke-line is visible on load
        if (mapInstance.getLayer('flurstuecke-line')) {
          mapInstance.setLayoutProperty(
            'flurstuecke-line',
            'visibility',
            'visible'
          )
        }

        // Move to user location if available
        const userLocation = await getUserLocation()

        if (userLocation) {
          const { latitude, longitude } = userLocation
          mapInstance.setCenter([longitude, latitude]) // Set the center immediately without animation
          mapInstance.setZoom(12) // Optionally set the zoom level immediately
        }
      })

      // Add event listener for click
      mapInstance.on('click', e => {
        // Query the features at the clicked point and give the handler the info on what layer to check
        mapClickHandler(
          e,
          mapInstance,
          markerRef,
          searchMarker,
          setOverlayData,
          setOverlayParcelsData,
          setShowOverlay,
          ['immo_layer-fill-extrusion', 'flurstuecke-fill']
        )
      })

      setMap(mapInstance)
    }

    if (!map) initializeMap()
    // Clean up on unmount
    return () => map?.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  const toggleLayerHandler = layerId => {
    toggleLayer(map, layersVisibility, setLayersVisibility, layerId)
  }

  const toggleViewHandler = () => {
    toggleView(map, is2DView, setIs2DView)
  }

  const closeOverlay = () => {
    setShowOverlay(false)

    // Check if the search marker exists and remove it
    if (searchMarker.current) {
      searchMarker.current.remove() // Remove the marker from the map
      searchMarker.current = null // Reset the reference
    }

    // Additionally, if you have a marker for `markerRef`, you can handle it here as well.
    if (markerRef.current) {
      markerRef.current.remove() // Remove any other markers if necessary
      markerRef.current = null // Reset the reference
    }
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen)
  }

  const handleApplyFilters = filterRange => {
    console.log('Received range from FilterSidebar:', filterRange)
    setFilters({ filterRange })

    if (!map) {
      console.error('Map instance is not initialized')
      return
    }

    // Remove existing filtered layers
    if (map.getLayer('immo_layer-fill-extrusion')) {
      console.log('Removing existing layer')
      map.removeLayer('immo_layer-fill-extrusion')
    } else {
      console.log('No existing layer to remove')
    }

    //Generate Filter Ranges or Props
    // TODO get them from config file
    console.log('FILTERRAGNE1: ', filterRange)
    const buildingTypes =
      filterRange.find(
        filter => filter.key === config.filters.global.buildingTypes
      )?.value || generateFilterOptions(config.filters.buildingTypesCategory)
    console.log('FILTERRAGNE2: ', filterRange)
    const heightRange = filterRange.find(
      filter => filter.key === config.filters.global.buildingHeight
    )?.value || [0, 100]
    console.log('FILTERRAGNE3: ', filterRange)
    const investRange = filterRange.find(
      filter => filter.key === config.filters.global.investPotential
    )?.value || [0, 1]

    map.addLayer(
      createFilteredLayer(
        'immo_layer-fill-extrusion',
        'immo_layer',
        'immo_layer',
        config.tileserver.layers[2].layerType, // TODO zu prüfen
        is2DView,
        config.filters.global.buildingTypes, // "mainFilter-prop"
        config.tileserver.layers[2].paint, //TODO zu prüfen
        // Add Filters here if they need to be applied TODO, fix automatically what filters exist
        //TODO fix filtering and make more generic
        [
          config.filters.global.buildingTypes,
          config.filters.global.buildingHeight,
          config.filters.global.investPotential
        ],
        [buildingTypes, heightRange, investRange]
      )
    )
    toggleLayer(map, layersVisibility, setLayersVisibility, [
      'immo_layer-fill-extrusion'
    ])
  }

  return (
    <div style={{
      width: "100%",
      height: isMobile ? "100svh" : "100%",
      position: "fixed",
      top: 0,
      left: 0,
    }}>
      <div ref={mapContainerRef} style={{ width: '100%', height: '100vh' }} />
      <Sidebar
        toggleLayer={toggleLayerHandler}
        toggleView={toggleViewHandler}
        is2DView={is2DView}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        handleApplyFilters={handleApplyFilters}
        activeFilters={filters}
        layersData={[
          {
            //Configuration how multiple Layery are triggered with one source or one button.
            isLayerVisible: [
              layersVisibility[
                `${config.tileserver.layers[2].sourceId}-${config.tileserver.layers[2].layerType}`
              ]
            ],
            layerName: [
              `${config.tileserver.layers[2].sourceId}-${config.tileserver.layers[2].layerType}`
            ],
            layerLabel: 'Immo_Layer'
          },
          {
            isLayerVisible: [
              layersVisibility[
                `${config.tileserver.layers[0].sourceId}-${config.tileserver.layers[0].layerType}`
              ],
              layersVisibility[
                `${config.tileserver.layers[1].sourceId}-${config.tileserver.layers[1].layerType}`
              ]
            ],
            layerName: [
              `${config.tileserver.layers[0].sourceId}-${config.tileserver.layers[0].layerType}`,
              `${config.tileserver.layers[1].sourceId}-${config.tileserver.layers[1].layerType}`
            ],
            layerLabel: 'landParcels'
          }
          // Add more layers as needed
        ]}
        layerMerge={[0, 1]}
      />
      <Navbar isNavbarOpen={isNavbarOpen} toggleNavbar={toggleNavbar} />
      {/* Legend Component */}
      <Legend
        layerLegend={layerLegend}
        isLayerVisible={layersVisibility['immo_layer-fill-extrusion']}
        handleApplyFilters={handleApplyFilters}
        activeFilters={filters}
        mainFilter={config.filters.global.buildingTypes}
      />
      {/* Control Container Header Map */}
      <div
        style={{
          position: 'absolute',
          top: 5,
          width: '100%',
          height: 50,
          display: 'flex',
          padding: isMobile ? '10px 10px 0 10px' : '10px 10px 0px 10px',
          zIndex: 1, // Ensure it's above other elements
          boxSizing: 'border-box'
        }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          {/* Button for Sidebar */}
          <div onClick={toggleSidebar} style={standardButtonStyle}>
            <FontAwesomeIcon icon={faLayerGroup} />
          </div>

          {/* SearchBar Component */}
          {map && <SearchBar map={map} marker={searchMarker} qSearchQuery={qSearchQuery}/>}

          {/* Navbar Component */}
          <div
            onClick={toggleNavbar}
            style={{ ...standardButtonStyle, marginLeft: 'auto' }}
          >
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>

        {/* Overlay Component */}
        {showOverlay && (
          <Overlay
            overlayData={overlayData}
            overlayParcelsData={overlayParcelsData}
            closeOverlay={closeOverlay}
            isSidebarOpen={isSidebarOpen}
          />
        )}

        {/* Version Sticker */}
        <VersionSticker wording='Alpha' stickerColor={colors.alphaColor} />
      </div>
    </div>
  )
}

export default Map
