import React from 'react'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { translate } from '../utils/translationManager'
import config from '../utils/config'
import logoBrandText from '../Assets/ImmoSachen_Logo_text_only.png'
import { useLanguage } from '../context/LanguageContext'
import useDeviceType from '../hooks/useDeviceType'
import 'bootstrap/dist/css/bootstrap.min.css'

const HeaderNavigation = () => {
  const navigate = useNavigate()
  const { language } = useLanguage()
  const { isMobile } = useDeviceType()

  return (
    <div style={{ backgroundColor: '#A8D5BA' }}>
      <Navbar expand='lg' className='bg-body-tertiary'>
        <Container>
          <Navbar.Brand href='/'>
            <img
              src={logoBrandText}
              alt='ImmoSachen Logo'
              style={{ height: '20px', marginRight: '8px', marginBottom: 6 }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>
              {/* <NavDropdown
                className={`navbar_color_default ${!isMobile ? 'navbar_margin_not_mobile' : ''}`}
                title='Produkte'
                id='basic-nav-dropdown'
              >
                <NavDropdown.Item
                  className={`navbar_color_default`}
                  href={config.apps.immoMap}
                >
                  {translate("immoMap", language)}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  className={`navbar_color_default`}
                  href={config.apps.immoCalculator}
                >
                  {translate("immoCalculator", language)}
                </NavDropdown.Item>
              </NavDropdown> */}
              
              <Nav.Link className={`navbar_color_default ${!isMobile ? 'navbar_margin_not_mobile' : ''}`} href={config.apps.immoMap}>
              {translate('immoMap', language)}
              </Nav.Link>
              <Nav.Link className={`navbar_color_default ${!isMobile ? 'navbar_margin_not_mobile' : ''}`} href={config.apps.immoCalculator}>
              {translate('immoCalculator', language)}
              </Nav.Link>
              <NavDropdown
                className={`navbar_color_default ${!isMobile ? 'navbar_margin_not_mobile' : ''}`}
                title='Ratgeber'
                id='basic-nav-dropdown'
              >
                <NavDropdown.Item href='/guide'>
                  {translate('guideOverview', language)}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  className={`navbar_color_default`}
                  href='/guide/steuern-immobilienkauf'
                >
                  Steuern beim Immobilienkauf
                </NavDropdown.Item>
                <NavDropdown.Item href='#action/3.2'>
                  Ratgeber Thema 2
                </NavDropdown.Item>
                <NavDropdown.Item href='#action/3.3'>
                  Ratgeber Thema 3
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default HeaderNavigation
