import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthEurope, faBars } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../utils/translationManager';
import { useLanguage } from '../../context/LanguageContext';
import AppsNavbar from './AppsNavbar';
import colors from '../../utils/colors';
import useStandardButtonStyle from '../../styles/standardButtonStyle';

const Navbar = ({ isNavbarOpen, toggleNavbar }) => {
  const { language, changeLanguage } = useLanguage();
  const standardButtonStyle = useStandardButtonStyle();

  // Local state to manage visibility of AppsNavbar
  const [isContentVisible, setContentVisible] = useState(isNavbarOpen);

  useEffect(() => {
    // Set content visibility based on isNavbarOpen
    if (isNavbarOpen) {
      setContentVisible(true); // Show content immediately when navbar opens
    } else {
      const timer = setTimeout(() => {
        setContentVisible(false); // Delay hiding content
      }, 300); // Delay for 300 ms

      return () => clearTimeout(timer); // Cleanup timer on unmount or change
    }
  }, [isNavbarOpen]);

  const handleLanguageToggle = () => {
    // Toggle between 'en' and 'de'
    changeLanguage(language === 'en' ? 'de' : 'en');
  };

  return (
    <>
      {isNavbarOpen && (
        <div
          onClick={toggleNavbar}
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Greyed-out background
            zIndex: 2,
          }}
        />
      )}
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: isNavbarOpen ? '330px' : '0px',
          backgroundColor: colors.background,
          zIndex: 3,
          transition: 'width 0.5s ease, opacity 0.5s ease', // Include opacity transition
          overflow: 'hidden', // Hide content when sidebar is closed
          borderRadius: '15px 0 0 15px',
          boxShadow: '-5px 20px 30px rgba(0, 0, 0, 0.4)',
        }}
      >
        {/* Button for Menu */}
        <button
          onClick={toggleNavbar}
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px', // Move button to the left side of the sidebar
            border: 'none',
            padding: '10px',
            cursor: 'pointer',
            background: 'none',
            color: colors.icon,
            zIndex: 4, // Higher zIndex to be above other content
            fontSize: '28px',
          }}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>

        {/* Navbar Content */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column', // Stack items vertically
            alignItems: 'flex-start',
            overflowY: 'auto', // Enable vertical scrolling
            boxSizing: 'border-box',
            width: '100%',
            marginBottom: 20,
            opacity: isContentVisible ? 1 : 0, // Control opacity based on visibility
            transition: 'opacity 1.3s ease', // Transition for opacity
          }}
        >
          {/* Language Toggle */}
          <div
            style={{
              textAlign: 'center',
              margin: '10px 0 0 auto', // This pushes the div to the right
            }}
          >
            <div
              onClick={handleLanguageToggle}
              style={{ ...standardButtonStyle }}
            >
              <FontAwesomeIcon icon={faEarthEurope} />
            </div>
            <div
              style={{
                fontSize: '10px',
                fontWeight: 'bold',
                color: colors.text,
                marginTop: '4px',
                width: 54,
              }}
            >
              {language === 'en' ? translate('de', language) : translate('en', language)}
            </div>
          </div>

          {/* Always Rendered Apps Navbar */}
          <AppsNavbar header={'test'} />
        </div>
      </div>
    </>
  );
};

export default Navbar;
